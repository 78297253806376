@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

html, body {
    background-color: #190c0c;
    color: #fff;
    font-family: 'Noto Sans', sans-serif;
    height: 100%;
    overflow: hidden;
    /* height: calc(var(--vh, 1vh) * 100); */
}

*::-webkit-scrollbar {
    display: none;
}

a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
}

p {
    margin:0px;
}

input, input:focus, input:active, textarea, textarea:focus, textarea:active {
    border:none;
    outline:none;
    font-size: 13px!important;
    color:#FFF;
    font-family:'Noto Sans', sans-serif;
    resize: vertical;
    width: 100%;
}

textarea {
    overflow-x:hidden;
}

div {
    font-size: 13px;
    font-family:'Noto Sans', sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.8);
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

button {
    border:none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    padding:0;
}
button:active {
    opacity: 0.6;
    outline: none;
}

#root {
    position: absolute;
    width: 390px;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #0D0404;
    overflow: hidden;
}

.video-react.video-react-fluid {
    padding: 0px!important;
    height: 100%!important;
}

.bgMain {
    background-color: #0D0404;
}

.appBody {
    width: 100%;
    height: 100%;
    min-height: 100%;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.appBody::-webkit-scrollbar {
    display: none;
}

.appBody.loading {
    overflow-y: hidden;
}

.appBody .appCont {
    padding-bottom:90px;
}

.inputContainer {
    width:100%;
    margin-top:8px;
}

/* start Component style */
.inputField {
    width: 100%;
    margin-top: 5px;
    padding: 10px 0px;
}

.inputLabel {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.6);
}

.inputTextCont {
    margin-top: 8px;
    position: relative;
    display: flex;
}

.inputTextCont input,
.inputTextCont textarea, .inputText {
    /*height: 40px;*/
    background-color: #1D1716;
    border-radius: 12px;
    padding: 9px 16px;
    color: rgba(255, 255, 255, 0.8);
    width: 100%;
}

.inputTextEdit{
    border-bottom: 1px solid rgba(255, 255, 255, 0.1)!important;
    background-color: transparent!important;
    border-radius: 0!important;
    padding: 8px 26px 8px 8px!important;
    font-weight: 500;
}

.inputIconCont {
    position: absolute;
    height: 100%;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 8px;
}

.inputIconCont > button {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.buttonFrame {
    width: 100%;
}

.buttonFrame > button, .buttonFrameCont {
    width: 100%;
    padding: 8px 16px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 12px;
    margin-top: 12px;
    height: 40px;
    display: flex;
    justify-content:center; 
    align-items:center;
    cursor: pointer;
    background-color: transparent;
}

.buttonFrame > button:active {
    opacity: 0.6;
}

.buttonFrame > button img {
    width:16px; 
    height:16px; 
    margin-right:10px;
}

.buttonFrame > button .buttonFrameText,
.buttonFrameText {
    font-weight: 600;
    font-size: 14px;
    color: #FFF;
    text-align: center;
    line-height:19px;
}

/* .kycItem {
    width: 33.33%;
} */

.modalParentContainer {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index:999;
}

.modalParentContainer.d-none, .d-none {
    display: none;
}

.modalParentContainer .modalContainer {
    display: flex;
    background-color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;
    height: 100%;
}

.modalParentContainer .modalContainer.flexEnd {
    align-items: flex-end;
}


.modalParentContainer .modalContainer .loadingView {
    display: flex;
    background-color:rgba(33, 33, 33, .8);
    padding: 0px; 
    border-radius: 12px; 
    align-items:center;
    justify-content: center;
}

.modalParentContainer .modalContainer .actionView {
    background-color: #191818; 
    padding: 10px; 
    border-radius: 18px 18px 0px 0px;
    width: 100%;
    padding-bottom: 10px;
    height: 60%;
    position: relative;
}

.rws-container {
    background-color: #191818!important; 
    border-radius: 18px 18px 0px 0px!important;
    width: 100%!important;
}

.rws-container .rws-close {
    background-color: #191818;
    color:#DB012E;
}

.modalParentContainer .modalContainer .actionView .actionItemCont {
    display: flex;
    margin: 10px 0px;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100% - 80px);
    overflow-x: hidden;
    position: absolute;
    width: 100%;
    gap:12px;
}

.modalParentContainer .modalContainer .actionView .actionItemCont .actionItem {
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    padding: 5px 0px;
    text-align: left;
}


.modalParentContainer .modalContainer .actionView .actionItemCont.picker .actionItem {
    padding: 8px 0px;
    text-align: center;
}


.modalParentContainer .modalContainer .actionView .actionItemCont.picker .actionItem.active {
    background-color: rgba(255, 255, 255, 0.08);
}

.modalParentContainer .modalContainer .actionView .actionItemCont.shareItemCont {
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: auto;
    gap: 34px;
}

.modalParentContainer .modalContainer .actionView .actionItemCont.shareItemCont .actionItem {
    text-align: center;
}

.modalParentContainer .modalContainer .actionView .actionItemCont.shareItemCont .actionItem img {
    width: 40px;
}

.modalParentContainer .modalContainer .actionView .actionItemCont.shareItemCont.contact svg {
    width: 100%;
    height: 100%;
}

.actionItem.ham {
    display: flex;
    align-items: center;
    gap: 8px;
}

.actionItemCont.datePicker .MuiPickersToolbar-root,
.actionItemCont.datePicker .MuiPickersCalendarHeader-root {
    padding: 4px 0px;
}

.actionItemCont.datePicker .MuiPickersLayout-actionBar {
    display: none;
}

.modalParentContainer .modalContainer .actionView .actionItemCont.datePicker .MuiPickersLayout-root {
    background-color: transparent!important;
}

.actionItemCont.datePicker .MuiPickersLayout-root .MuiTypography-root.MuiTypography-overline {
    display: none!important;
}

.actionItemCont.datePicker .MuiPickersLayout-root .MuiButtonBase-root.MuiIconButton-root {
    color:rgba(255, 255, 255, 0.54)!important;
}

.actionItemCont.datePicker .MuiPickersLayout-root .MuiTypography-root.MuiDayCalendar-weekDayLabel,
.actionItemCont.datePicker .MuiPickersLayout-root .MuiPickersDay-root.MuiPickersDay-dayWithMargin {
    color:rgba(255, 255, 255, 0.8)!important;
}

.actionItemCont.datePicker .MuiPickersLayout-root .MuiPickersDay-root.MuiPickersDay-dayWithMargin.Mui-selected {
    color: #FFF!important;
    background-color: #DB012E;
}

.actionItemCont.datePicker .MuiMultiSectionDigitalClock-root {
    justify-content: center;
}

.actionItemCont.datePicker .MuiMultiSectionDigitalClock-root .MuiButtonBase-root:hover {
    background-color:rgba(219, 1, 46, 0.04);
}
.actionItemCont.datePicker .MuiMultiSectionDigitalClock-root .MuiButtonBase-root.Mui-selected {
    background-color: #DB012E;
}


.actionItemCont.datePicker .MuiInputBase-root.MuiInputBase-colorPrimary {
    color: #FFF;
    font-weight: 600;
}

.actionItemCont.datePicker .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
    border-color: #FFF;
}

.backArrow {
    position: absolute;
    left:15px; 
    top:0px;
}


.backArrow > button {
    position: absolute;
    left:0px; 
    top:15px;
    cursor: pointer;
}

.tabbedPanelCont {
    display: flex;
    flex-wrap: nowrap;
    gap:3px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top:32px;
}

.tabbedPanelCont .tabbedBorder {
    height:4px; 
    background-color:rgba(255, 255, 255, 0.3)
}


.tabbedPanelCont > div:first-of-type .tabbedBorder {
    border-radius: 3px 0px 0px 3px;
}

.tabbedPanelCont > div:last-of-type .tabbedBorder {
    border-radius: 0px 3px 3px 0px;
}

.tabbedPanelCont .tabbedBorder.activeBorder {
    background-color: #DB012E;
}


.tabbedPanelCont .tabbedText,
.tabbedText {
    color:rgba(255, 255, 255, 0.3);
    text-align:center; 
    margin-top:8px;
    font-weight: 500;
    font-size:12px;
    line-height: 19px;
}

.tabbedPanelCont .tabbedText.activeText {
    color: #DB012E;
}

.kycList {
    overflow-x: hidden;
    height: calc(100% - 242px);
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    padding-top: 32px;
    padding-bottom: 32px;
    row-gap: 20px;
    justify-content: space-between;
}

.heroTitle {
    font-size: 24px;
    color: #FFF;
    line-height: 33px;
    font-weight: 700;
}

.postOwner {
    display:flex; 
    margin-top:10px;
    align-items: center;
    gap: 5px;
}

.postOwnerText {
    font-weight:700;
    font-size: 14px;
    color: #FFF;
    margin-left: 5px;
    line-height: 20px;
}

.postOwnerTextl {
    font-weight: 600;
    font-size: 13px;
    color: #FFF;
    margin-left: 5px;
}

.listContainer {
    display: flex;
    flex-direction: column;
    justify-content:center; 
    align-items:center;
    margin:0px 20px;
}

.sectionDisplay {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sectionHeader {
    margin: 0px 20px;
    margin-bottom: 20px;
}

.sectionTitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    color: #FFF;
}

.sectionTopLink {
    color: #DB012E;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
}

.displayImageContainer {
    width:345px; 
    height:234px; 
    border-radius:16px;
    position:relative;
}

.displayImage {
    width:100%; 
    height:100%;
    border-radius:16px;
    object-fit: cover;
}

.paidLabel {
    background-color:rgba(8, 0, 0, 0.85); 
    padding: 8px 15px; 
    position:absolute; 
    bottom:-1px; 
    right: 0px; 
    max-width:90px;
    display: flex;
    justify-content:center;
    align-items:center;
    border-top-left-radius:16px; 
    border-bottom-right-radius:16px;
}

.itemOwner {
    color: #FFF;
    font-size:14px;
    line-height:16px;
    font-weight: 700;
}

.flatList {
    display: flex;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.flatList::-webkit-scrollbar {
    display: none;
}

.flatList.horizontal {
    overflow-y: hidden;
    min-width: 100%;
    flex-direction: row;

}

.searchBarComp {
    background-color:rgba(255, 255, 255, 0.12); 
    border-radius:16px;
    display: flex; 
    flex-direction:row; 
    padding:5px 16px; 
    align-items:center;
    gap: 10px;
}
.searchBarComp .inputText {
    padding-left: 0px;
    padding-right: 0px;
}

.heroBtn {
    background-color:#DB012E; 
    border:none; 
}

.geryBtn {
    background-color:rgba(255, 255, 255, 0.16);
}

.bodyText {
    font-size: 13px;
    line-height:17px;
}

.borderColor {
    border-color: rgba(255, 255, 255, 0.12);
}

.borderDivider {
    width: 100%; 
    height: 1px; 
    background-color: rgba(255, 255, 255, 0.08);
    margin: 30px 0px;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.15);
    outline: none;
    opacity: 1;
    border-radius: 5px;
}
/* .slider::before {
    content: "";
    height: 4px;
    background:#DB012E;
    width: auto;
    display: inline-block;
} */

.slider_track {
    height: 5px;
    background: #DB012E;
    width: 100%;
    display: inline-block;
    position: absolute;
    top: calc(50% + 1px);
    left: 0px;
    border-radius: 5px 0px 0px 5px;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #FFF;
    cursor: pointer;
    z-index: 1;
    position: relative;
}

.sticky {
    position: fixed;
    top: 0;
    width: calc(100% - 40px);
    left: 50%;
    z-index: 99;
    background-color: #0c0404;
    padding: 12px 0px 0px!important;
    transform: translateX(-50%);
}

.rateHeader {
    font-weight: 700;
    font-size:16px;
    line-height:16px;
    color: #FFFFFF;
    text-align: center;
}

.rateDesc {
    font-weight: 400;
    font-size:14px;
    line-height:16px;
    color: #9E9B9B;
    margin-top: 13px;
    text-align:center;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch .switch_slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #767577;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 24px;
}
  
.switch .switch_slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    border-radius: 50%;
    left: 2px;
    bottom: 2px;
    background-color: #f4f3f4;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch input:checked + .switch_slider {
    background-color: #DB012E;
}
  
.switch input:focus + .switch_slider {
    box-shadow: 0 0 1px #DB012E;
}
  
.switch input:checked + .switch_slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
    background-color: #fff;
}

.moneyInfo .detailTitle {
    font-weight:700;
    font-size:14px;
    line-height:19px;
    color:#FFF;
}

.moneyInfo .detailSection {
    display:flex;
    justify-content:center;
    margin-top: 16px;
    width:100%;
}

.moneyInfo .detailDesc {
    font-weight:400;
    font-size: 14px;
    color:rgba(255, 255, 255, 0.6);
    line-height:16px;
}

.moneyInfo .detailDescPrice {
    color: rgba(255, 255, 255, 1)
}


/* end Component Style */

/* Start Authentication Flow */
.authContainer {
    display: flex;
    padding: 0px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.authContainer > .logoContainer {
    width: 150px;
    height: 150px;
}


.authContainer > .logoContainer > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.forgotText {
    display: flex;
    margin-left: auto;
    margin-top: 5px;
}


.forgotText > a,
.forgotText > button {
    color:#DB012E; 
    font-size:14px; 
    font-weight: 600;
    line-height: 19px;
}

.forgotText > button {
    font-size: 12px;
}

.SignupFooter {
    display: flex;
    position: absolute;
    bottom:0; 
    justify-content:center; 
    border-top:1px solid rgba(255, 255, 255, 0.12); 
    width:100%;
    padding:16px 20px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

.SignupFooter > span {
    margin-right: 5px;
}

.authContainer > .register {
    padding-top:100px;
    justify-content: flex-start;
    overflow: hidden;
    height: auto;
    text-align: center;
    width: 100%;
}

.authContainer > .register .header {
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    margin-bottom: 32px;
    color:#FFF;
}

.authContainer > .register .desc {
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    color:#FFF;
    margin-bottom: 24px;
}

/* End Authentication Flow */

/* start global css */
.sectionTitle {
    font-size: 18px;
    line-height: 25px;
    color: #FFF;
}
/* end global css */

/* Start Profile Generator */
.profilePicture {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
}

.profilePictureUser {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.profilePicBackground {
    background-color: #c4c4c4;
}

.profilePictureUser.isText {
    background-color:#710183; 
    display: flex;
    justify-content:center; 
    align-items:center;
}
/* End Profile Generator */


/* start bottom Tab */
.bottomTabContainer {
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    padding: 12px 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
    gap: 42px;
    left: 0px;
    background-color: #0c0404;
    z-index: 999;
}

.bottomTabContainer > a {
    text-align: center;
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    color: #BEBEBE;
}

.bottomTabContainer > a.active {
    color: #FFF;
}

.bottomTabContainer > a > span:not(.createEventIcon):first-of-type {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom:3px;
}
/* End bottom tab */

/* Landing Page */
.hero {
    background-size: cover;
    background-position: center;
    background-color:#7f7f7f;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.hero > div:not(.paidLabel) {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(19, 19, 19, 0.40) 0.31%, rgba(19, 19, 19, 0.30) 6.80%, rgba(19, 19, 19, 0.00) 20.19%, rgba(0, 0, 0, 0.00) 52.96%, rgba(0, 0, 0, 0.30) 68.59%, rgba(0, 0, 0, 0.40) 99.48%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
}

.hero > .paidLabel {
    border-bottom-right-radius:0px;
}

.hero .logo {
    width:24px;
    height: 39px;
}

.hero .navBar {
    display: flex;
    padding: 20px 20px 10px 20px;
    align-items: center;
}

.hero p {
    margin: 0px;
}

.hero .navBar ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    gap: 10px;
}

#videoID > div {
    width: 100%!important;
    height: 100%!important;
}

.searchBar {
    background-color:rgba(29, 23, 22, 0.95);
    padding:5px 20px; 
    border-radius:16px; 
    display:flex;  
    align-items:center;
    position: absolute;
    width: calc(100% - 40px);
    top: -8px;
}

.locationIndicator {
    display:flex; 
    margin-top:32px;
    margin: 0px 20px;
}
  
.locationRegion {
    color:#FFF;
    font-size:14px; 
    font-weight:500; 
    line-height:19px;
}

.locationRegionText {
    color:#DB012E;
    font-size:14px; 
    font-weight:700; 
    line-height:19px; 
    margin-left:5px;
}

.toggleFilterItem {
    margin-left: 15px; 
    padding:10px 16px;
    border: 1px solid #FFF;
    border-radius: 32px;
}

.toggleFilterItemActive {
    background-color: #DB012E!important;
    border-width: 1px; 
    border-color: #DB012E!important;
}

.toggleFilterItemText { 
    color: #FFF;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
}

/* end Landing Page */

/* Ticket page */
.ticketBg {
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    position: relative;
    background-color: rgba(52, 39, 38, 0.7);
}

.ticketBg.image {
    /* overflow: hidden; */
}

.ticketBg.image > div {
    backdrop-filter:blur(8px); 
    height: 100%;
    border-radius: 16px;
    position: relative; 
    width:100%;
}

.actionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:4px;
}

.actionIconBtn {
    width:35px; 
    height:35px;
    border-radius:30px;
    background-color:rgba(255, 255, 255, 0.35); 
    display: flex;
    justify-content:center; 
    align-items:center;
}

/* end Ticket page */

/* Profile Page */
.wwd .header {
    font-size: 20px;
    font-weight: 900;
    color: #FFF;
}
.wwd .header > span {
    color:#DB012E
}

.wwd p {
    color:rgba(255, 255, 255, 0.60);
    font-size: 14px;
    font-weight: 500;
}

/* end profile page */

.fp_header {
    color: #FFF;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    margin-top: 100px;
    margin-bottom: 0px;
    text-align: center;
}

.fp_desc {
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    margin-top: 30px;
    text-align: center;
}

.nomText {
    color: #FFF;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
}

.fp_forgotText {
    color: #DB012E;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    margin-left:5px;
}

/* Start Create Event style */
.create__event .tabbedPanel {
    margin-top:30px;
    display: flex; 
    margin-bottom: 8px;
    gap: 3px;
    justify-content: center;
    padding: 0px 30px;
}

.create__event .tabbedPanel > div {
    width: 100%;
}

.create__event .tabbedPanel .tabbedBorder {
    width: 100%; 
    height:4px; 
    background-color: rgba(255, 255, 255, 0.3);
}

.create__event .tabbedPanel .tabbedBorder.tabbedEdgeStart {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.create__event .tabbedPanel .tabbedBorder.tabbedEdgeEnd {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.create__event .tabbedPanel .tabbedBorder.activeBorder {
    background-color:#DB012E;
}

.create__event .tabbedPanel .tabbedText {
    color:rgba(255, 255, 255, 0.3);
    text-align:center; 
    margin-top:8px;
    font-weight:600;
    font-size:13px;
    line-height: 19px;
}

.create__event .tabbedPanel .tabbedText.activeText {
    color:#DB012E;
}

.create__event .tabbedPanelContent {
    width: 100%;
    height: calc(100% - 110px);
    display: flex;
    align-items: flex-start;
    /* overflow: hidden;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch; */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
}


.create__event .tabbedPanelContent > .tabbedPanelContent__item {
    width: 100%;
    height: 100%;
    /* scroll-snap-align: start;
    transform-origin: center start; */
    flex-shrink: 0;
    /* padding: 0px 30px; */
    overflow-x: hidden;
}

.create__event .tabbedPanelContent > .tabbedPanelContent__item > div {
    padding-bottom: 20px;
}

.errorBorder {
    border: 1px solid #DB012E;
}

.skipText {
    color:#DB012E;
    font-size:14px;
    font-weight: 600; 
    line-height: 19px;
}

.autoBtn {
    padding:4px 12px;
    border-radius:16px;
    width: auto;
    height:30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.greyAutoBtn {
    background-color: rgba(255, 255, 255, 0.16);
}

.fillAutoBtn {
    background-color: #DB012E;
}

.autoBtnText {
    font-size:12px;
    font-weight:600;
    line-height:14px;
    color: #FFF;
}

.ddText {
    font-size:14px;
    font-weight:500;
    color:rgba(255, 255, 255, 0.6);
    line-height:19px;
}

.create__event .promotion__container,
.review_asset__container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
}

.create__event .promotion__container > .promotion__item,
.review_asset__container > .review_asset__item {
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
    transform-origin: center start;
    flex-shrink: 0;
    /* padding: 0px 30px; */
    overflow-x: hidden;
}


@media screen and (max-width: 768px) {
    #root {
        width: 100%;
    }
}